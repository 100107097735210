@import url('./sass/styles.css'); 


* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}



p{
  font-size: large;
  font-weight: 300;
}

h1,h2,h3,h4,h5,h6,a,span{
  
  font-weight: 500;
}

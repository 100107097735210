

@media screen and (max-width: 480px) {
    .total{
        flex-direction: column;
    }
    .text_left,.text_right,.left{
width: 100%;
    }
  
 


    section{
        flex-direction: column;
        width: 100%;
    }
    article{
        width: 100%;
    }
   
    body,html{
        height: 100%;
    }
    .total{
        flex-direction: column-reverse;
        width: 100%;
    }

    .font-title{
        font-weight: 100;
    }
 

    .dix,.onze{
        visibility: hidden;
    }

    .vertical{
        position: absolute !important;
        top: 0;
        font-size: 4rem;
        right: 2rem;

    }

}

@media screen and (max-width: 850px) {

    .nav{
        width: 95%;
       top: 3rem;
       left:0;
       justify-content: start;
       align-items: start;
       position: fixed;
      #mega-menu-full{
          width: 50%;
      }
    }
}

/* Pour les navigateurs basés sur WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 5px; /* Largeur de la barre de défilement */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #1b1919; /* Couleur de fond de la piste */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #4eddf3; /* Couleur de la poignée de défilement */
    border-radius: 5px; /* Coins arrondis */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color:none; /* Couleur de la poignée de défilement au survol */
  }


.article_left{
height: 60rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5,1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(100px, auto);
    @media screen and (min-width: 1536px) {
      height: 100rem;
    }
    @media screen and (max-width: 1535px) {
      overflow: hidden;
      heigh:50rem;
      grid-gap: 0.5rem;
    }

}
.svg-icon{
fill: red !important;
}
.vertical{ 
    writing-mode: vertical-lr; /* ou vertical-lr selon l'orientation désirée */
    text-orientation: upright;
    letter-spacing: -3rem;
    font-family: "Monoton", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .verticalPopular{
    
    writing-mode: vertical-lr; /* ou vertical-lr selon l'orientation désirée */
    text-orientation: upright;
    letter-spacing: 0.2rem;
    font-family: "Monoton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.font-title{
    font-family: "Monoton", sans-serif;
    font-weight: 400;
    font-style: normal; 

}
.one {
    grid-column: 2 / 4;
    grid-row: 1/3;
    @media screen and (max-width: 850px) {
      grid-row: 1/2;

    }


  
  }
  .two {
    grid-column: 1 / 2;
    grid-row: 1 ;
  }
  .three {
    grid-column: 1;
    grid-row: 2 / 5;
    @media screen and (max-width: 850px) {
      
      grid-row: 2 / 3;
    }
  }
  .four {
    grid-column: 3;
    grid-row: 3;
    @media screen and (max-width: 850px) {
      
      grid-column: 2/4;
      
      grid-row: 2 / 4;
  }
  }
  .five {
    grid-column: 2;
    grid-row: 4;
  }
  .six {
    grid-column: 3;
    grid-row: 4;
}
.sept{
    z-index: 30;

    grid-column: 1/2;
    grid-row: 5/6;
  }
  .huit{
    grid-column: 2/3;
    @media screen and (max-width: 850px) {

      grid-column: 1/2;
      grid-row: 3/4;

    }

  }
  .neuf{
  
    grid-column: 2/4;
    grid-row: 4/8;
    overflow: hidden;
    @media screen and (max-width: 850px) {
      grid-row: 4/5;
      grid-column: 1/2;
      
    }
  }
 
  .quatorze{
    z-index: 30;
    overflow: hidden;
  
    grid-column: 1/2;
    grid-row: 5/9;
    @media screen and (max-width: 850px) {
      
      grid-row: 4/7;
      grid-column: 2/4;

    }
  }

